import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import style from './index.module.scss';

const SignUpLink = () => (
  <a className={style.signUpLink} href='https://manage.remark.ninja'>
    Sign up for free
  </a>
);

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <p className={style.siteIntro}>
      Easy and flexible comment system for content creators.
    </p>
    <SignUpLink />
    <h2>What is Remark Ninja?</h2>
    <p>
      A service to help you add commenting and community management to any site.
    </p>
    <h2>Features</h2>
    <ul>
      <li>
        Support a safe subset of Markdown that preserves your site aesthetic
      </li>
      <li>No sign-in needed to comment</li>
      <li>New-comment notification</li>
      <li>Cache user input on the client side</li>
      <li>More to come ...</li>
    </ul>
    <h2>Easy to integrate</h2>
    <ul>
      <li>One-line integration for GatsbyJS and other React-based sites;</li>
      <li>
        Use our{' '}
        <a href='https://app.swaggerhub.com/apis-docs/Remark-Ninja/remark-ninja-public-api/1.0.0'>
          RESTful API
        </a>{' '}
        for any site or mobile app;
      </li>
      <li>Direct support for other platforms coming soon.</li>
    </ul>
    <h2>Privacy is a priority</h2>
    <ul>
      <li>We do not use analytics tools to track you or your users;</li>
      <li>
        We do not collect any extra data that is not used to implement the
        service itself;
      </li>
      <li>Users can export and delete their data anytime.</li>
      <li>All data is stored in the EU.</li>
    </ul>
    <h2>Free to use now</h2>
    <p>
      Remark Ninja is free to use during beta and perhaps for a long time, but
      we might need to make it a paid service in the future to develop and
      sustain it. If/when that happens, we promise that
    </p>
    <ul>
      <li>the pricing will be friendly to users of all levels;</li>
      <li>generous discounts will be given to existing users;</li>
      <li>adequate time will be given to users who want to migrate away.</li>
    </ul>
    <SignUpLink />
  </Layout>
);

export default IndexPage;
